import { Button, Container, LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import GeneralErrors from "../../components/form/general-errors";
import LayoutLogged from "../../components/layout/logged";
import PagePadding from "../../components/layout/page-padding";
import api from "../../services/api";
import { formatDateTimeComplete, formatDateTimeDay } from "../../services/date";
import { moneyFormat, numberFormat } from "../../services/number";
import { translateValue } from "../../services/translation";
import Swal from 'sweetalert2';
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function StatementView() {
  const [id] = useQueryParam("id");
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState();
  const [isToPay, setIsToPay] = useState(false);

  const toPay = () => {
    Swal.fire({
      title: 'Confirma o pagamento desta comissão?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Sim, pagar`,
      cancelButtonText: `Cancelar`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setErrors(undefined);
          setLoading(true);
          await api.post(`/statement/${id}/pay`);

          Swal.fire({
            title: 'Comissão marcada como paga com sucesso',
            icon: 'success',
            confirmButtonColor: '#000',
          });
          setIsToPay(false);
        } catch (e) {
          setErrors(e?.response?.data || 'Erro desconhecido');
        }

        setLoading(false);
      }
    })
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/statement/${id}`);
        const d = response.data;

        setIsToPay(d.operation === 'credit' && !d.debit_id);

        const values = [
          {
            primary: 'Cliente', value: (
              <a href={`/workflow/card?workflow=lead_client&id=${d.lead_client_id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>
                {d.lead_client?.name}
              </a>
            )
          },
          { primary: 'Descrição', value: d.description },
          { primary: 'Referência', value: formatDateTimeDay(d.reference) },
          {
            primary: 'Usuário', value: (
              <a href={`/user/update?id=${d?.user?.id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>
                {d?.user?.name}
              </a>
            )
          },
          {
            primary: 'Organização', value: (
              <a href={`/organization/update?id=${d?.referrer_organization?.id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>
                {d?.referrer_organization?.name}
              </a>
            )
          },
          { primary: 'Usuário criador', value: d?.created_user?.name },
          { primary: 'Origem', value: translateValue('statement', 'origin', d.origin) },
          { primary: 'Produto', value: translateValue('statement', 'product_type', d.product_type) },
          { primary: 'Operação', value: translateValue('statement', 'operation', d.operation) },
          { primary: 'Criado', value: formatDateTimeComplete(d.created_at) },
          { primary: 'Valor', secondary: 'usuário', value: moneyFormat(d.amount_user) },
          { primary: 'Valor', secondary: 'organização', value: moneyFormat(d.amount_organization) },
          { primary: 'Pontos', value: numberFormat(d.points) },
          { primary: 'Pontos expiram em', value: formatDateTimeDay(d.points_expire) },
          { primary: 'Pendente', value: d.pending_reason },
          { primary: 'Aprovado', value: formatDateTimeComplete(d.approved_date) },
          { primary: 'Usuário aprovador', value: d.approved_user?.name },
          { primary: 'Ajuste', value: moneyFormat(d.amount_adjustment) },
          { primary: 'Origem valor', value: moneyFormat(d.origin_amount) },
          { primary: 'Origem % usuário', value: `${d.origin_pct_user * 100}%` },
          { primary: 'Origem % organização', value: `${d.origin_pct_organization * 100}%` },
        ];

        if (d.debit_id) {
          values.push({
            primary: 'Débito', value: (
              <a href={`/statement/view?id=${d.debit_id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>Ir para ver o registro</a>
            )
          });
        }

        if (d.credit_id) {
          values.push({
            primary: 'Crédito', value: (
              <a href={`/statement/view?id=${d.credit_id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>Ir para ver o registro</a>
            )
          });
        }

        setData(d);
        setValues(values);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }

      setLoading(false);
    })();
  }, []);

  if (ifNotHaveAccessRedirect('statement.write')) return <></>;

  return (
    <LayoutLogged title="Comissão">
      <PagePadding>
        {errors && <GeneralErrors errors={errors} />}
        <Container maxWidth="md">
          {loading ? <LinearProgress /> : (
            <>
              {isToPay && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ color: 'white' }}
                  onClick={toPay}
                  fullWidth
                >PAGAR</Button>
              )}
              <Paper>
                <List>
                  {values.map(value => {
                    return (
                      <ListItem>
                        <ListItemText
                          primary={value.primary}
                          secondary={value.secondary}
                        />
                        <ListItemSecondaryAction>{value.value}</ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </>
          )}
        </Container>
      </PagePadding>
    </LayoutLogged>
  );
}