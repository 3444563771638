export function moneyFormat(amount, maximumFractionDigits = 2) {
  const opts = { style: 'currency', currency: 'BRL' };

  if (maximumFractionDigits > 0) {
    opts['maximumFractionDigits'] = maximumFractionDigits;
  }

  return new Intl.NumberFormat('pt-BR', opts).format(amount, opts);
}

export function numberFormat(number, maximumFractionDigits = 2) {
  const opts = {};

  if (maximumFractionDigits > 0) {
    opts['maximumFractionDigits'] = maximumFractionDigits;
  }

  return new Intl.NumberFormat('pt-BR', opts).format(number);
}

export function formatByType(type, number, maximumFractionDigits = 2) {
  if (type === 'money') {
    return moneyFormat(number, maximumFractionDigits);
  } else {
    return numberFormat(number, maximumFractionDigits);
  }
}